import './App.css';
import UrlShortener from './urlShortener';

function App() {
  return (
    <div >
     <UrlShortener />
    </div>
  );
}

export default App;
