// src/UrlShortener.js
import React, { useState } from 'react';

function UrlShortener() {
  const [url, setUrl] = useState('');
  const [shortenedUrl, setShortenedUrl] = useState('');

  const handleShortenUrl = async () => {
    const response = await fetch('/api/shorten', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ url }),
    });
    const result = await response.json();
    setShortenedUrl(result.shortenedUrl);
  };

  return (
    <div className="container">
      <h1>URL Shortener</h1>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter URL to shorten"
      />
      <button onClick={handleShortenUrl}>Shorten</button>
      {shortenedUrl && (
        <div className="shortened-url">
          Shortened URL: <a href={shortenedUrl}>{shortenedUrl}</a>
        </div>
      )}
    </div>
  );
}

export default UrlShortener;